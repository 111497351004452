import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UserB2CPayload } from "@app/models/user/NewUserB2CPayload.class";
import { environment } from '@environments/environment';
import { SnackbarService } from "@services/snackbar.service";
import { TokenProviderService } from "./tokenprovider.service";
import { firstValueFrom } from "rxjs";

@Injectable()
export class B2CService {
  private b2cBaseUri: string = `${environment.routes.b2cBaseUrl}`;
  private logicAppInvokeUri: string = `${environment.routes.apiEndpoint}/manual/paths/invoke`;

  constructor(
    private http: HttpClient,
    private tokenProviderService: TokenProviderService,
    private snackbarService: SnackbarService
  ) { }

  public async inviteUser(user: UserB2CPayload) {
    //Create URI for triggering sending invite through B2C
    let inviteUri = this.b2cBaseUri + '/B2C_1A_SEND_INVITE/oauth2/v2.0/authorize'
      + `?client_id=${environment.b2c.clientId}`
      + `&redirect_uri=${environment.b2c.redirectUri}`
      + '&scope=openId'
      + '&nonce=defaultNonce'
      + '&response_type=id_token';

    //Get token from token provider for sending data to B2C
    const tokenResponse = await firstValueFrom(this.tokenProviderService.getToken(user));
    //Add token to URI
    inviteUri += ('&id_token_hint=' + tokenResponse.accessToken);

    const logicAppRequestBody = {
      triggerFlow: 'SendInvite',
      sendInviteUrl: inviteUri,
      deleteUserUrl: ''
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Ocp-Apim-Subscription-Key': environment.apimKey,
        'RequestBody': JSON.stringify(logicAppRequestBody)
      })
    };

    //Send invite request and return when request gives a response
    return await firstValueFrom(this.http.post(this.logicAppInvokeUri, {}, httpOptions));
  }

  public editUser() {
    console.error('Edit user not implemented in B2C');
    return;
  }

  public async deleteUser(user: UserB2CPayload) {
    //Create URI for triggering user delete through B2C
    let deleteUri = this.b2cBaseUri + '/B2C_1A_DELETE_USER/oauth2/v2.0/authorize'
      + `?client_id=${environment.b2c.clientId}`
      + `&redirect_uri=${environment.b2c.redirectUri}`
      + '&scope=openId'
      + '&nonce=defaultNonce'
      + '&response_type=id_token';

    //Get token from token provider for sending data to B2C
    const tokenResponse = await firstValueFrom(this.tokenProviderService.getToken(user));

    //Add token to URI
    deleteUri += ('&id_token_hint=' + tokenResponse.accessToken);

    const logicAppRequestBody = {
      triggerFlow: 'DeleteUser',
      sendInviteUrl: '',
      deleteUserUrl: deleteUri
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Ocp-Apim-Subscription-Key': environment.apimKey,
        'RequestBody': JSON.stringify(logicAppRequestBody)
      })
    }

    return await firstValueFrom(this.http.post(this.logicAppInvokeUri, {}, httpOptions));
  }
}
